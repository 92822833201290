// @flow
/* eslint-disable import/max-dependencies */
import React, {
  type StatelessFunctionalComponent,
} from "react";
import {
  Box,
  Button,
  type Theme,
} from "@mui/material";
import type { Filters } from "@fas/cpa-state-manager/redux/actions/table/actions";
import { FilterBuilder } from "@fas/cpa-cabinet-ui";
import dayjs from "dayjs";
import en from "dayjs/locale/en-gb";
import { makeStylesTyped } from "@fas/cpa-cabinet-ui/lib/helpers";
import { TRANSACTION_TABLE } from "../../helpers/constants";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import {
  DateCellComponent,
  FooterComponent,
} from "../DateRangePickerComponent";
import { mapOptions, presets } from "../../helpers/generators";

type Props = {
  filters: Filters,
  loading: boolean,
  onChangeTableFilters: (string, Filters) => mixed,
  onGetData: () => mixed,
  departmentsDropdown: DropDownObjItemType[],
  countriesDropdown: DropDownObjItemType[],
  customersDropdown: DropDownObjItemType[],
  platformsDropdown: DropDownObjItemType[],
  agesDropdown: DropDownObjItemType[],
  setIsAdvancedFilterEnabled: (boolean) => mixed,
  isAdvancedFilterEnabled: boolean,
}

const FilterWrapper: StatelessFunctionalComponent<*> = ({ children }) => (
  <Box display="inline-block" pr={1} pb={1}>{children}</Box>
);

const useStyles = makeStylesTyped((theme: Theme) => ({
  input: {
    width: "180px",
  },
  dateRange: {
    width: "253px",
    "& textarea": {
      textAlign: "center",
    },
  },
  label: {
    paddingBottom: theme.spacing(1),
  },
}));

const TransactionReportFilters: StatelessFunctionalComponent<Props> = ({
  filters,
  loading,
  onChangeTableFilters,
  onGetData,
  departmentsDropdown,
  countriesDropdown,
  customersDropdown,
  platformsDropdown,
  agesDropdown,
  setIsAdvancedFilterEnabled,
  isAdvancedFilterEnabled,
}: Props) => {
  const classes = useStyles();

  const handleFiltersChange = (newFilters: Filters) => {
    onChangeTableFilters(TRANSACTION_TABLE, newFilters);
  };

  const handleRangeChangeDateRangePicker = ({ startDate, endDate }) => {
    onChangeTableFilters(TRANSACTION_TABLE, { dateFrom: startDate, dateTo: endDate });
  };

  const dynamicFieldsOptions: DropDownObjItemType[] = [
    { value: "customer", label: "Company Name" },
  ];

  return (
    <Box display="flex">
      <Box flexGrow={1}>
        <FilterBuilder
          data-testid="filters"
          WrapperItemComponent={FilterWrapper}
          // $FlowFixMe
          filters={filters}
          // $FlowFixMe
          onFiltersChange={handleFiltersChange}
          items={[
            {
              type: "select",
              filterKey: "department",
              filterProps: {
                label: "Department",
                className: classes.input,
                classes: { formLabel: classes.label },
                disabled: loading,
                "data-testid": "filter-department",
                options: [
                  { value: "", label: "All" },
                  ...departmentsDropdown,
                ].map(mapOptions),
              },
            },
            {
              type: "select",
              filterKey: "country",
              filterProps: {
                label: "Country",
                className: classes.input,
                classes: { formLabel: classes.label },
                disabled: loading,
                "data-testid": "filter-country",
                options: [
                  { value: "", label: "All" },
                  ...countriesDropdown,
                ].map(mapOptions),
              },
            },
            {
              type: "select",
              filterKey: "customer",
              filterProps: {
                label: "Company Name",
                className: classes.input,
                classes: { formLabel: classes.label },
                disabled: loading,
                "data-testid": "filter-customer",
                options: [
                  { value: "", label: "All" },
                  ...customersDropdown,
                ].map(mapOptions),
              },
            },
            {
              type: "textField",
              filterKey: "invoiceId",
              filterProps: {
                label: "Transaction id",
                className: classes.input,
                classes: { formLabel: classes.label },
                disabled: loading,
                type: "number",
                "data-testid": "filter-invoiceId",
              },
            },
            {
              type: "select",
              filterKey: "platform",
              filterProps: {
                label: "Platform",
                className: classes.input,
                classes: { formLabel: classes.label },
                disabled: loading,
                "data-testid": "filter-platform",
                options: [
                  { value: "", label: "All" },
                  ...platformsDropdown,
                ].map(mapOptions),
              },
            },
            {
              type: "select",
              filterKey: "age",
              filterProps: {
                label: "Age Group",
                className: classes.input,
                classes: { formLabel: classes.label },
                disabled: loading,
                "data-testid": "filter-age",
                options: [
                  { value: "", label: "All" },
                  ...agesDropdown,
                ].map(mapOptions),
              },
            },
            {
              type: "dateRangePicker",
              filterKey: "dateRangePicker",
              filterProps: {
                disabled: loading,
                classes: { input: { root: classes.dateRange }, formLabel: classes.label },
                label: "Time Period",
                startDate: filters.dateFrom,
                endDate: filters.dateTo,
                onChangeDate: handleRangeChangeDateRangePicker,
                onChangePreset: handleRangeChangeDateRangePicker,
                presets,
                locale: en,
                formatDate: (date) => dayjs(date).format("DD-MM-YYYY"),
                "data-testid": "filter-date-range-picker",
                DateCellComponent,
                FooterComponent,
              },
            },
          ]}
        />
        { isAdvancedFilterEnabled && (
          <Box>
            <FilterBuilder
              WrapperItemComponent={FilterWrapper}
              // $FlowFixMe wrong type
              filters={filters}
              onFiltersChange={handleFiltersChange}
              items={[
                {
                  type: "select",
                  filterKey: "currency",
                  filterProps: {
                    label: "Conversion currency",
                    className: classes.input,
                    classes: { formLabel: classes.label },
                    disabled: loading,
                    "data-testid": "filter-currency",
                    disableClearable: true,
                    options: [
                      { value: "$", label: "Only in $" },
                      { value: "€", label: "Only in €" },
                      { value: "£", label: "Only in £" },
                    ].map(mapOptions),
                  },
                },
                {
                  type: "multiSelect",
                  filterKey: "dynamicFields",
                  filterProps: {
                    label: "Show columns",
                    className: classes.dateRange,
                    classes: { formLabel: classes.label },
                    disabled: loading,
                    "data-testid": "filter-dynamicFields",
                    options: dynamicFieldsOptions.map(mapOptions),
                  },
                },
              ]}
            />
          </Box>
        )}
      </Box>
      <Box alignSelf="flex-start" display="flex" pt={2} flexShrink={0}>
        <Box pr={2}>
          <Button
            variant="contained"
            color={isAdvancedFilterEnabled ? "primary" : "secondary"}
            name="advancedFilter"
            onClick={() => {
              setIsAdvancedFilterEnabled(!isAdvancedFilterEnabled);
            }}
          >
            Advanced filter
          </Button>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            name="applyFilters"
            disabled={loading}
            onClick={onGetData}
          >
            Apply filters
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default TransactionReportFilters;
